<template>
  <CSLayout :type="'notice'">
    <div class="container flex flex-col notice-content">
      <h3>{{ data.title }}</h3>
      <p class="date">{{ date }}</p>

      <hr />

      <div class="contents w-full h-full flex flex-wrap">
        <div v-html="data.contents"></div>
      </div>

      <!-- <img v-if="!!image" class="image" :src="image" /> -->
    </div>
  </CSLayout>
</template>

<script>
import dayjs from "dayjs";
import CSLayout from "@/components/CSLayout.vue";
import services from "@/services";

export default {
  name: "NoticeDetail",
  components: {
    CSLayout,
  },
  data() {
    return {
      data: {},
    };
  },
  async created() {
    await this.getNotice();
  },
  computed: {
    date: function() {
      const { createdAt: createdAt } = this.data;
      return dayjs(createdAt).format("YYYY.MM.DD");
    },
    image: function() {
      const { hasThumbnail } = this.data;
      if (hasThumbnail) {
        const BASE_URL = (process.env.VUE_APP_IS_LOCAL_SERVER == 1 ? process.env.VUE_APP_API_BASEURL_LOCAL : process.env.VUE_APP_API_BASEURL_SERVER);
        const { path, hashName: hashName } = hasThumbnail;
        const url = `${BASE_URL}${path}${hashName}`;
        return url;
      }

      return null;
    },
  },
  methods: {
    getNotice: async function() {
      const { success, data } = await services.noticeDetail(
        this.$route.params.id
      );

      if (success) {
        this.data = data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.notice-content {
  padding-top: 65px;
  padding-bottom: 65px;

  h3 {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -1.2px;
    text-align: left;
    color: #353535;
  }

  .date {
    margin-top: 13px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.14px;
    text-align: left;
    color: #aaa;
  }

  hr {
    width: 80%;
    margin-top: 16px;
    margin-bottom: 33px;
  }

  .contents {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.14;
    letter-spacing: -0.14px;
    text-align: left;
    color: #656565;
  }

  .contents /deep/ {
    box-sizing: border-box;

    div {
      box-sizing: border-box;
    }

    p {
      box-sizing: border-box;
      word-break: break-all;
    }
  }

  .image {
    width: 627px;
    object-fit: contain;
    margin-top: 76px;
    margin-bottom: 160px;
  }
}

@media (max-width: 1023px) {
  .notice-content {
    .contents {
      img {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 639px) {
  .notice-content {
    overflow: hidden;
    padding-top: 30px;
    h3 {
      font-size: 15px;
    }
    .date {
      font-size: 12px;
      margin-top: 5px;
      line-height: 1.3;
    }
    hr {
      width: 100%;
    }
    .contents {
      font-size: 12px;
    }
  }
}
</style>
